import React  from 'react';


export default class HeroLayout extends React.Component {
	//http://momentum-template.webflow.io/#about
	render() {
		return (
			<div className="d-flex text-center text-white hero-layout">
				<div className="decor1"></div>
				<div className="decor2"></div>
				<div className="content-container d-flex w-100 p-3 mx-auto flex-column">
					{this.props.children}
				</div>
			</div>
		);
	}
}
