import React  from 'react';
import API from './components/API';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import DynamicFormField from './components/DynamicFormField';

export default class RSVPDetails extends API {

	constructor (props) {
		super(props)
		this.state = {
			reservation: props.reservation,
			editNameField: [],
			saved: false
		}
	}

	updateValue(value, pIx, key) {
		var reservation = this.state.reservation;
		reservation.people[pIx][key] = value;
		this.setState({reservation: reservation});
	}

	submitChanges() {
		if(!document.getElementById('invitee-details').reportValidity()) {return false;}
		this.api('/invitees/', 'PUT', (data)=>{this.setState({saved: true}); console.log(data)}, {inviteCode: this.props.inviteCode, reservation: this.state.reservation});
		this.props.addNotification('Invitation updated!', 'You can come back to this page and update your invitation at any time.')
	}

	render() {
		return (
			<>
				<p className="mt-4">Please confirm the following details for each person:</p>

				{this.props.reservation.people.map((person) => {return person.brunch_invited}).includes(true) ?
					<Alert key={'secondary'} variant={'secondary'}>
						Note: You are also invited to a brunch the following morning (Sunday October 15th, 11:00 AM) hosted by the bride's family:<br/>
						123 Rue Laurent, Dollard-des-Ormeaux, QC H9A 1X5
					</Alert>
				:
					''
				}

				<form id="invitee-details">
					{this.props.reservation.people.map((person, pIx) => {
						return (
							<ListGroup className={'text-start mb-4'} style={{boxShadow: '0 0 50px #ccc'}} key={"details-"+pIx}>
								<ListGroup.Item>
									{this.state.editNameField.includes(pIx) ?
										<DynamicFormField
											apiKey='name'
											label="Edit Name"
											value={this.state.reservation.people[pIx].name}
											maxLength={300}
											apiEndPoint = {'/invitee/'+person.id}
											apiDefaultBodyPaylod = {{inviteCode: this.props.inviteCode}}
											changeCallback = {(value) => {this.updateValue(value, pIx, 'name')}} >
										</DynamicFormField>
									:
										<h4 className="">{person.name} <FontAwesomeIcon icon={solid('pen')} style={{cursor: 'pointer'}} onClick={() => {this.setState({editNameField: [...this.state.editNameField, pIx]})}}/></h4>
									}
									<DynamicFormField
										apiKey='attending_ceremony'
										type="select"
										label="Attending Ceremony"
										required={true}
										value={this.state.reservation.people[pIx].attending_ceremony}
										apiEndPoint = {'/invitee/'+person.id}
										apiDefaultBodyPaylod = {{inviteCode: this.props.inviteCode}}
										changeCallback = {(value) => {this.updateValue(value === 'true', pIx, 'attending_ceremony')}}>
										<option value="">--Please choose--</option>
										<option value="true">Yes</option>
										<option value="false">No</option>
									</DynamicFormField>
									<DynamicFormField
										apiKey='attending_cocktail'
										type="select"
										label="Attending Cocktails"
										required={true}
										value={this.state.reservation.people[pIx].attending_cocktail}
										apiEndPoint = {'/invitee/'+person.id}
										apiDefaultBodyPaylod = {{inviteCode: this.props.inviteCode}}
										changeCallback = {(value) => {this.updateValue(value === 'true', pIx, 'attending_cocktail')}}>
										<option value="">--Please choose--</option>
										<option value="true">Yes</option>
										<option value="false">No</option>
									</DynamicFormField>
									<DynamicFormField
										apiKey='attending_reception'
										type="select"
										label="Attending Reception"
										required={true}
										value={this.state.reservation.people[pIx].attending_reception}
										apiEndPoint = {'/invitee/'+person.id}
										apiDefaultBodyPaylod = {{inviteCode: this.props.inviteCode}}
										changeCallback = {(value) => {this.updateValue(value === 'true', pIx, 'attending_reception')}}>
										<option value="">--Please choose--</option>
										<option value="true">Yes</option>
										<option value="false">No</option>
									</DynamicFormField>
									<DynamicFormField
										apiKey='meal_choice'
										type="select"
										label="Meal Choice"
										required={this.state.reservation.people[pIx].attending_reception!==false}
										value={this.state.reservation.people[pIx].meal_choice}
										apiEndPoint = {'/invitee/'+person.id}
										apiDefaultBodyPaylod = {{inviteCode: this.props.inviteCode}}
										changeCallback = {(value) => {this.updateValue(value, pIx, 'meal_choice')}}>
										<option value="">--Please choose--</option>
										<option value="chicken">Chicken</option>
										<option value="fish">Fish</option>
										<option value="vegetarian">Vegetarian</option>
									</DynamicFormField>
									<DynamicFormField
										apiKey='dietary_restrictions'
										type="textarea"
										label="Dietary Restrictions & Special Observations"
										maxLength={2000}
										rows={2}
										value={this.state.reservation.people[pIx].dietary_restrictions}
										apiEndPoint = {'/invitee/'+person.id}
										apiDefaultBodyPaylod = {{inviteCode: this.props.inviteCode}}
										changeCallback = {(value) => {this.updateValue(value, pIx, 'dietary_restrictions')}} >
									</DynamicFormField>
									{this.state.reservation.people[pIx].brunch_invited===true ?
										<DynamicFormField
											apiKey='brunch_confirmed'
											type="select"
											label="Are you joining the sunday brunch?"
											required={true}
											value={this.state.reservation.people[pIx].brunch_confirmed}
											apiEndPoint = {'/invitee/'+person.id}
											apiDefaultBodyPaylod = {{inviteCode: this.props.inviteCode}}
											changeCallback = {(value) => {this.updateValue(value === 'true', pIx, 'brunch_confirmed')}}>
												<option value="">--Please choose--</option>
												<option value="true">Yes</option>
												<option value="false">No</option>
										</DynamicFormField>
									:
										''
									}
								</ListGroup.Item>
							</ListGroup>
						)
					})}
				</form>
				{this.state.isLoading===true ? 
					<FontAwesomeIcon icon={solid("spinner")} className='fa-2xl mt-3 mb-5 start-50' />
				:
					<Button variant="primary" className="start-50 mt-4 mb-5" onClick={this.submitChanges.bind(this)}>{this.state.saved===true ? 
							<FontAwesomeIcon icon={solid("check")} />
						:
							''
					}Update all</Button>
				}
			</>
		);
	}
}