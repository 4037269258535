import React  from 'react';

export default class Footer extends React.Component {
	render() {
		return (
			<footer className="mt-auto">
				<p>(c) 2023 paulstaubin.ca</p>
			</footer>
		);
	}
}
