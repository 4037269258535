import React  from 'react';
import { Link } from "react-router-dom";
import BasicLayout from './layouts/BasicLayout';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
export default class Hotels extends React.Component {

	render() {
		return (
			<BasicLayout>
				<Header/>

				<main className="px-3">
					<h1 className="mt-5">Hotels</h1>
					<Row className="mt-4 text-start">
						<Col md="6">
							<a href="https://flotel.ca/flotel-salaberry-de-valleyfield/"><h5>Flotel Valleyfield</h5></a>
							<ListGroup>
								<ListGroup.Item>240 Victoria Rue,<br/>Salaberry-De-Valleyfield QC<br/> J6T1B8<br/></ListGroup.Item>
								<ListGroup.Item><Link id="flotel-valleyfield-directions" to={`/directions/flotel-valleyfield`}>Time to Venue: 15 min</Link></ListGroup.Item>
								<ListGroup.Item>Time to West Island: 30 min</ListGroup.Item>
								<ListGroup.Item>Time to downtown Montreal: 50 min</ListGroup.Item>
							</ListGroup>
						</Col>
						<Col md="6">
							<a href="https://hotelplazavalleyfield.com/"><h5>Hotel Plaza Valleyfield</h5></a>
							<ListGroup>
								<ListGroup.Item>40 Av du Centenaire,<br/> Salaberry-de-Valleyfield QC<br/> J6S 3L6<br/></ListGroup.Item>
								<ListGroup.Item><Link id="plaza-valleyfield-directions" to={`/directions/plaza-valleyfield`}>Time to Venue: 10 min</Link></ListGroup.Item>
								<ListGroup.Item>Time to West Island: 30 min</ListGroup.Item>
								<ListGroup.Item>Time to downtown Montreal: 50 min</ListGroup.Item>
							</ListGroup>
						</Col>
					</Row>
					<Row className="mt-4 text-start">
						<Col md="6">
							<a href="http://www.chateau-vaudreuil.com/"><h5>Chateau Vaudreuil</h5></a>
							<ListGroup>
								<ListGroup.Item>21700 Route Transcanadienne,<br/> Vaudreuil-Dorion QC<br/> J7V 8P7<br/></ListGroup.Item>
								<ListGroup.Item><Link id="chateau-vaudreuil-directions" to={`/directions/chateau-vaudreuil`}>Time to Venue: 20 min</Link></ListGroup.Item>
								<ListGroup.Item>Time to West Island: 15 min</ListGroup.Item>
								<ListGroup.Item>Time to downtown Montreal: 35 min</ListGroup.Item>
							</ListGroup>
						</Col>
						<Col md="6">
							<a href="https://www.hilton.com/en/hotels/yuleses-embassy-suites-montreal-airport/?SEO_id=GMB-AMER-ES-YULESES&y_source=1_OTQwNDA5NS03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D"><h5>Embassy Suites Pointe-Claire</h5></a>
							<ListGroup>
								<ListGroup.Item>6300 Trans-Canada Hwy,<br/> Pointe-Claire, Quebec<br/> H9R 1C2<br/></ListGroup.Item>
								<ListGroup.Item><Link id="embassy-suites-directions" to={`/directions/embassy-suites`}>Time to Venue: 30 min</Link></ListGroup.Item>
								<ListGroup.Item>Time to West Island: 5 min</ListGroup.Item>
								<ListGroup.Item>Time to downtown Montreal: 25 min</ListGroup.Item>
							</ListGroup>
						</Col>
					</Row>
				</main>



				<Footer/>
			</BasicLayout>
		);
	}
}