import React  from 'react';
import { NavLink } from "react-router-dom";

export default class Header extends React.Component {
	render() {
		return (
			<header className="mt-3">
				<div>
					<h3 className="float-md-start mb-0">K & P</h3>
					<nav className="nav nav-masthead justify-content-center float-md-end">
						<NavLink
							id="home" 
							className="nav-link"
							to={`/`}>
							Home
						</NavLink>
						<NavLink
							id="rsvp" 
							className="nav-link"
							to={`/rsvp`}>
							RSVP
						</NavLink>
						<NavLink
							id="registry" 
							className="nav-link"
							to={`/registry`}>
							Registry
						</NavLink>
						<NavLink
							id="hotels" 
							className="nav-link"
							to={`/hotels`}>
							Hotels
						</NavLink>
						<NavLink
							id="directions" 
							className="nav-link"
							to={`/directions`}>
							Directions
						</NavLink>
					</nav>
				</div>
			</header>
		);
	}
}
