import React  from 'react';
import BasicLayout from './layouts/BasicLayout';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import RSVPCode from './RSVPCode';
import RSVPDetails from './RSVPDetails';

export default class RSVP extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			reservation: null,
			inviteCode: null,
			notifications: []
		}
	}

	codeChangeCallback(reservation, inviteCode) {
		this.setState({reservation:reservation, inviteCode:inviteCode});
	}

	addNotification(title, description, time="just now", delay=15000) {
		this.setState({
			notifications: [
				...this.state.notifications,
				{
					title: title,
					description: description,
					time: time,
					delay: delay
				}
			]
		});
	}


	render() {
		return (
			<BasicLayout notifications={this.state.notifications}>
				<Header/>

				<main className="px-3">
					<h1 className="mt-5">RSVP</h1>

					{this.state.reservation === null ?
						<RSVPCode codeChangeCallback={this.codeChangeCallback.bind(this)} />
					:
						<RSVPDetails reservation={this.state.reservation} inviteCode={this.state.inviteCode} addNotification={this.addNotification.bind(this)} />
					}

				</main>


				<Footer/>
			</BasicLayout>
		);
	}
}