import React  from 'react';
import HeroLayout from './layouts/HeroLayout';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import { Link } from "react-router-dom";

export default class Home extends React.Component {

	render() {
		return (
			<HeroLayout>
				<Header/>

				<main className="px-3 hero">
					<h1 className="names mt-5">KELLY BIRENBAUM</h1>
					<h1 className="names">&</h1>
					<h1 className="names">PAUL G. ST-AUBIN</h1>
					<p className="lead2 mt-5">are celebrating their wedding</p>
					<p className="lead mt-5">Saturday 14 October, 2023, 3:30PM</p>
					<p className="lead mt-5">
						<Link
							id="rsvp-btn" 
							className="btn btn-lg btn-primary fw-bold"
							to={`/rsvp`}>
							RSVP
						</Link>
					</p>

					<p className="lead2 mt-5">Moulin Callières Di Jasmin</p>
					<p>
						308 Chemin du Fleuve <br/>
						Coteau-du-Lac, QC, J0P 1B0
					</p>

				</main>


				<Footer/>
			</HeroLayout>
		);
	}
}