import React  from 'react';
import BasicLayout from './layouts/BasicLayout';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import Alert from 'react-bootstrap/Alert';

export default class Registry extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			reservation: null
		}
	}

	codeChangeCallback(reservation) {
		this.setState({reservation:reservation});
	}


	render() {
		return (
			<BasicLayout>
				<Header/>

				<main className="px-3">
					<h1 className="mt-5">Registry</h1>

					<Alert key={'secondary'} variant={'secondary'} className="mt-4">
						The wedding registry is available at Linen Chest<br/>
						<a href="https://www.registry.linenchest.com" target="_blank">https://www.registry.linenchest.com</a><br/>
						Be sure to enter "Kelly" and "Birenbaum" in the two fields to be able to access it!
					</Alert>
					
					<p className="mt-5">
						<a
							href="https://www3.linenchest.com/WD110AWP/WD110Awp.exe/CONNECT/Wedding_Gift_Registry?_WWREFERER_=&_WWNATION_=3&NATION=3"
							className="btn btn-lg btn-primary fw-bold"
							target="_blank">
							Go To Registry
						</a>
					</p>
				</main>


				<Footer/>
			</BasicLayout>
		);
	}
}