import React  from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

export default class BasicLayout extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			notificationsShow: props.notifications.map(() => {return true})
		}
	}

	static defaultProps = {
		notifications: []
	};

	setAlertShowState(nIx, value) {
		let notificationsShow = this.state.notificationsShow;
		notificationsShow[nIx] = value;
		this.setState({notificationsShow: notificationsShow});
	}

	render() {
		return (
			<div className="d-flex h-100 text-center">
				<div className="decor1"></div>
				<div className="decor2"></div>
				<div className="content-container d-flex w-100 h-100 p-3 mx-auto flex-column">
					{this.props.children}
				</div>
				<ToastContainer className="mt-4 me-4">
					{this.props.notifications.map((notification, nIx) => {
						return (
							<Toast key={'notification-'+nIx} onClose={() => {this.setAlertShowState(nIx, false)}} show={this.state.notificationsShow[nIx]} delay={notification.delay} autohide>
								<Toast.Header>
									<img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
									<strong className="me-auto">{notification.title}</strong>
									<small className="text-muted">{notification.time}</small>
								</Toast.Header>
								<Toast.Body>{notification.description}</Toast.Body>
							</Toast>
						)
					})}
				</ToastContainer>
			</div>
		);
	}
}
