import React  from 'react';
import { withRouter } from '../hocs/withRouter';
import API from './components/API';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

export default withRouter(class RSVPCode extends API {

	constructor (props) {
		super(props)
		this.state = {
			code: props.router.params.key
		}
		this.inputReference = React.createRef();
	}


	componentDidMount() {
		this.inputReference.current.focus();
	}

	componentDidUpdate() {
		//this.validateCode();
	}


	updateCode(e) {
		this.setState(
			{code:e.target.value},
			() => {this.validateCode()}
		);
	}


	validateCode() {
		if(this.state.code.length>=4) {
			this.api('/invitees/', 'POST', (data)=>{this.props.codeChangeCallback({people:data}, this.state.code);}, {inviteCode: this.state.code});
		}
	}


	render() {
		return (
			<>
				<p className="mt-4">Please enter your access code to find your invitation</p>

				<Row>
					<Col xs={4}>

					</Col>
					<Col xs={4}>
						<InputGroup className="mb-3">
							<Form.Control
								aria-label="Access Code"
								defaultValue={this.state.code}
								onChange={this.updateCode.bind(this)}
								maxLength="4"
								className={'code-input'}
								ref={this.inputReference} 
							/>
							
						</InputGroup>
					</Col>
					<Col xs={4}>
						{this.state.status===403 ? 
							<OverlayTrigger overlay={<Tooltip id={'invalid-code-tooltip'}>This access code is invalid. Please try again.</Tooltip>}>
								<FontAwesomeIcon icon={solid("triangle-exclamation")} className='fa-2xl mt-3' style={{float:'left'}} />
							</OverlayTrigger>
						: this.state.isLoading===true ? 
							<FontAwesomeIcon icon={solid("spinner")} className='fa-2xl mt-3' style={{float:'left'}} />
						:
							''
						}
					</Col>
				</Row>
			</>
		);
	}
})