import React  from 'react';

export default class API extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ...this.getCommonState(),
    }
  }


  getCommonState() {
    //Placeholder. Expand this object to add state variables to be initiated
    return {
      isLoading: null,
      status: null,
    }
  }


  api(apiURL, method, successCallback, body=null) {
    this.setState({isLoading: true})
    fetch(apiURL, {
      method: method,
      headers: { 'Content-Type': 'application/json' },
      ...(body!=null ? {body:JSON.stringify(body)} : {})
    })
    .then(res => {
      // Unfortunately, fetch doesn't send (404 error) into the cache itself
      this.setState({
        status: res.status
      })
      if(res.status >= 400) {
        console.log(res)
        throw new Error(`Server responded with error ${res.status}!`);
      }
      return res.json()
    })
    .then(data => {
      successCallback(data)
      this.setState({
        isLoading: false
      })
    },
    // Note: it's important to handle errors here
    // instead of a catch() block so that we don't swallow
    // exceptions from actual bugs in components
    err => {
      console.log(err)
      this.setState({
        isLoading: 'error'
      })
    });
  }
}
