import React  from 'react';
import { withRouter } from '../hocs/withRouter';
import BasicLayout from './layouts/BasicLayout';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import { GoogleMap, LoadScript, Marker, DirectionsService, DirectionsRenderer } from '@react-google-maps/api';
import DynamicFormField from './components/DynamicFormField';

export default withRouter(class Directions extends React.Component {

	static defaultProps = {
		venueCoordinates: {
			lat: 45.2895793,
			lng: -74.1768102
		}
	};

	constructor (props) {
		super(props)

		this.state = {
			response: null,
			travelMode: 'DRIVING',
			origin: this.getOriginFromKey(props),
			destination: props.venueCoordinates.lat+','+props.venueCoordinates.lng
		}

		this.directionsCallback = this.directionsCallback.bind(this)
	}


	
	getOriginFromKey(props) {
		switch((props ?? this.props).router.params.key) {
			case 'flotel-valleyfield':
				return 'Flotel Valleyfield';
			case 'plaza-valleyfield':
				return 'Plaza Valleyfield';
			case 'chateau-vaudreuil':
				return 'Chateau Vaudreuil';
			case 'embassy-suites':
				return 'Embassy Suites Pointe-Claire';
			default:
				return ''
		}
	}


	directionsCallback (response) {
		console.log(response)

		if (response !== null) {
			if (response.status === 'OK') {
				this.setState(
					() => ({
						response
					})
				)
			} else {
				console.log('response: ', response)
			}
		}
	}


	render() {
		
		return (
			<BasicLayout>
				<Header/>

				<main className="px-3">
					<h1 className="mt-5">Directions</h1>

					<div className='row mb-4'>
						<div className='col-12'>
							<DynamicFormField
								value={this.getOriginFromKey()}
								changeCallback={(origin) => {
									this.setState({origin: origin, response: null})
								}}
								placeholder="I am coming from..."
							/>
						</div>
					</div>



					<LoadScript googleMapsApiKey="AIzaSyD18UE3bLmhyh8iTEnwdbxH6EC3IYDbUiQ">
						<GoogleMap
							mapContainerStyle={{
								width: '100%',
								height: '400px'
							}}
							center={this.props.venueCoordinates}
							zoom={16}>
							
							<Marker
								onLoad={(marker) => {}}
								position={this.props.venueCoordinates}
							/>
							{
							(
								this.state.destination !== '' &&
								this.state.origin !== '' &&
								this.state.response === null // Important, so that this only gets called once
							) && (
								<DirectionsService
									// required
									options={{
										destination: this.state.destination,
										origin: this.state.origin,
										travelMode: this.state.travelMode
									}}
									// required
									callback={this.directionsCallback}
									// optional
									onLoad={directionsService => {
										console.log('DirectionsService onLoad directionsService: ', directionsService)
									}}
									// optional
									onUnmount={directionsService => {
										console.log('DirectionsService onUnmount directionsService: ', directionsService)
									}}
								/>
							)
						}

						{
							this.state.response !== null && (
								<DirectionsRenderer
									// required
									options={{
										directions: this.state.response
									}}
									// optional
									onLoad={directionsRenderer => {
										console.log('DirectionsRenderer onLoad directionsRenderer: ', directionsRenderer)
									}}
									// optional
									onUnmount={directionsRenderer => {
										console.log('DirectionsRenderer onUnmount directionsRenderer: ', directionsRenderer)
									}}
								/>
							)
						}
						</GoogleMap>
					</LoadScript>

					<p className="lead2 mt-5">Moulin Callières Di Jasmin<br/>
						308 Chemin du Fleuve <br/>
						Coteau-du-Lac, QC, J0P 1B0</p>

				</main>


				<Footer/>
			</BasicLayout>
		);
	}
})