import React  from 'react';
import API from './API.js'
import InlineIsLoadingIcon from './InlineIsLoadingIcon.js'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

export default class DynamicFormField extends API {
	static defaultProps = {
		suggestions: [], // For ReactTags Primarily
		apiDefaultBodyPaylod: {}
	};


	constructor(props) {
		super(props);
		let value = (props.value===undefined || props.value===null ? (props.type==='tags' ? [] : '') : props.value)
		this.state = {
			...this.getCommonState(),
			value: value,
			isValid: true
		}
		this.lastValue = value
		this.inactivityTimoutId = null;
	}


	componentDidUpdate(prevProps) {
		//manually update the state if the props it is derived from is updated; react won't do this on it's own (and I guess that makes sense)
		if (prevProps.value !== this.props.value) {
			this.setState({value: this.props.value})
		}
	}


	updateField(e) {
		if(this.lastValue===e.target.value || !this.props.apiEndPoint) {
			this.setState({isLoading:null});
			this.update()
			return false
		}else{
			if(!e.target.checkValidity()) {
				this.setState({isLoading:'invalid'});
				return false
			}else{}
		}
		this.setState({value: e.target.value}, this.update)
		window.clearTimeout(this.inactivityTimoutId);
	}


	update() {
		if(this.props.apiEndPoint) {
			this.api(this.props.apiEndPoint, 'PUT', (data) => {
				this.lastValue = this.state.value
			}, {
				...this.props.apiDefaultBodyPaylod,
				[this.props.apiKey]: this.state.value
			})
		}
		this.props.changeCallback(this.state.value)
	}


	checkForInactivity(e) {
		this.setState({value: e.target.value})
		this.lastUpdate = Date.now();
		window.clearTimeout(this.inactivityTimoutId);
		this.inactivityTimoutId = setTimeout(() => {
			if (Date.now() - this.lastUpdate > 500) {
				this.updateField(e);
			}
		}, 1500);
	};


	renderInput() {
		const { apiKey, apiEndPoint, apiDefaultBodyPaylod, children, changeCallback, label, value, ...props } = this.props;

		switch (this.props.type) {
			case 'textarea':
				return (
					<Form.Group className="mb-3">
						<Form.Label>{this.props.label}</Form.Label>
						<Form.Control
							{...props}
							aria-label={this.props.label}
							defaultValue={this.state.value===null ? '' : this.state.value}
							onBlur={this.updateField.bind(this)}
							as="textarea" />
					</Form.Group>
				)
			case 'select':
				return (
					<Form.Group className="mb-3">
						<Form.Label>{this.props.label}</Form.Label>
						<Form.Select
							{...props}
							aria-label={this.props.label}
							defaultValue={this.state.value===null ? '' : this.state.value}
							onChange={this.updateField.bind(this)}>
							{this.props.children}
						</Form.Select>
					</Form.Group>
				)
			case 'url':
			case 'email':
				return (
					<input 
						{...props}
						type={this.props.type}
						className="form-control"
						value={this.state.value}
						onBlur={this.updateField.bind(this)}
						onChange={this.checkForInactivity.bind(this)}
					/>
				)
			default:
				return (
					<InputGroup>
						<Form.Control
							{...props}
							onChange={this.checkForInactivity.bind(this)}
							value={this.state.value}
							onBlur={this.updateField.bind(this)}
						/>
					</InputGroup>
				)
		}
	}


	render(){
		return (
			<div style={{'position':'relative'}}>
				{this.renderInput()}
				<InlineIsLoadingIcon 
					actionWord="Saving"
					style={{position:'absolute', 
						...(this.props.type==='select' ?
							{
								right:'30px',
								top: '30px'
							}
						:
							{
								right:'10px',
								top:'50%',
								transform:'translateY(-50%)'
							}
						)}}
					isLoading={this.state.isLoading}
					status={this.state.status} />
			</div>
		)
	}
}