import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './assets/css/react-bootstrap.min.css';
import './assets/css/index.css';
import './assets/css/cover.css';
import Home from './views/Home';
import RSVP from './views/RSVP';
import Hotels from './views/Hotels';
import Registry from './views/Registry';
import Directions from './views/Directions';

//import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
    <BrowserRouter>
    <Routes>
      <Route path="/">
        <Route index element={<Home />} />
        <Route path='rsvp' element={<RSVP />} />
        <Route path='rsvp/:key' element={<RSVP />} />
        <Route path='registry' element={<Registry />} />
        <Route path='hotels' element={<Hotels />} />
        <Route path='directions' element={<Directions />} />
        <Route path='directions/:key' element={<Directions />} />
      </Route>
    </Routes>
  </BrowserRouter>
//</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
